/**
 * Component - v2.1.0
 */

// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal, Icon, Styled, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Wrapper = Styled.View`
  ${(props) => (props.isResponsive && props.mobile ? 'width: 100%;' : 'width: 400px;')}
  ${(props) => (props.isResponsive && props.mobile ? 'flex: 1;' : '')}
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
`;

const Header = Styled.View`
  padding-horizontal: 20px;
`;

const TitleRow = Styled.View`
  padding-top: 20px;
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  flex: 1;
  font-weight: 700;
`;

const IconView = Styled.View`
`;

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Touchable = Styled.Touchable`
  justify-content: center;
`;

const CloseButton = ({onPress}) => {
  return (
    <Touchable onPress={onPress}>
      <Icon color={colors.gray.primary} size={18} source={Icon.Times} />
    </Touchable>
  );
};

const SmallModalV1 = ({isResponsive, isOpen, icon, title, subtitle, onClose, children}) => {
  const responsive = useResponsive();

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Overlay pointerEvents={'box-none'}>
        <Wrapper {...responsive} isResponsive={isResponsive}>
          <Header>
            <TitleRow>
              <IconView>{icon}</IconView>
              {!!icon && !!title && <Space width={8} />}
              <Title>{title}</Title>
              <CloseButton responsive={responsive} onPress={onClose} />
            </TitleRow>
            <Subtitle>{subtitle}</Subtitle>
          </Header>
          {children}
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SmallModalV1.propTypes = {
  isResponsive: PropTypes.bool,
  title: PropTypes.string,
};

SmallModalV1.defaultProps = {
  isResponsive: true,
  title: null,
};

export default SmallModalV1;
