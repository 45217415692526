// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment, List} from '@supermove/utils';

// App
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

import Project from './Project';

const getEditSalesTaxAction = (invoice, {updateSalesTaxModal}) => ({
  label: 'Edit sales tax',
  onPress: () => updateSalesTaxModal.handleOpen(),
  isDisabled: _.includes([InvoiceStatus.FINALIZED], invoice.status),
});

const getExportAction = (invoice, {handleSendInvoiceToCodatSubmit}) => ({
  label: 'Queue for Quickbooks Export',
  onPress: () => {
    handleSendInvoiceToCodatSubmit();
  },
  isDisabled: false,
});

const getFinalizeAction = (invoice, {finalizeInvoiceModal}) => ({
  label: 'Finalize invoice',
  onPress: () => {
    finalizeInvoiceModal.handleOpen();
  },
  isDisabled: invoice.status !== InvoiceStatus.PAID,
});

const getUnfinalizeAction = (invoice, {unfinalizeInvoiceModal}) => ({
  label: 'Unfinalize invoice',
  onPress: () => {
    unfinalizeInvoiceModal.handleOpen();
  },
  isDisabled: invoice.status !== InvoiceStatus.FINALIZED,
});

const getEditAction = (invoice, {handleEditStorageInvoice, confirmInvoiceEditModal}) => ({
  label: 'Edit invoice',
  onPress: () => {
    if (invoice.status === InvoiceStatus.PAID) {
      confirmInvoiceEditModal.handleOpen();
    } else {
      handleEditStorageInvoice();
    }
  },
  isDisabled: _.includes([InvoiceStatus.FINALIZED], invoice.status),
});

const getSendAction = (invoice, {updateInvoiceDrawer}) => {
  const {
    status,
    project: {
      isComplete,
      projectType: {category},
    },
  } = invoice;

  // We don't expose send invoice for move projects unless it is complete
  const isDisabled =
    (category === ProjectTypeCategory.MOVE && !isComplete) ||
    _.includes([InvoiceStatus.PAID, InvoiceStatus.FINALIZED], status);

  return {
    label: 'Send invoice',
    onPress: () => {
      updateInvoiceDrawer.handleOpen();
    },
    isDisabled,
  };
};

const getResendAction = (invoice, {handleResendInvoiceSubmit}) => {
  const {
    status,
    date,
    emailTemplateId,
    project: {
      isComplete,
      projectType: {category},
    },
  } = invoice;

  // We don't expose resend invoice for move projects unless it is complete
  // Resend invoice for move projects is also disabled when invoice was never
  // sent before
  const isDisabled =
    (category === ProjectTypeCategory.MOVE && !isComplete) ||
    _.includes([InvoiceStatus.DRAFT, InvoiceStatus.SCHEDULED], status) ||
    !date ||
    !emailTemplateId;
  return {
    label: 'Resend invoice',
    onPress: () => {
      handleResendInvoiceSubmit();
    },
    isDisabled,
  };
};

const getViewAction = (invoice, {navigator}) => ({
  label: 'View invoice',
  onPress: () => navigator.pushNewTab(`/invoices/${invoice.uuid}/view`),
});

const getChargeCreditCardAction = (
  invoice,
  {chargeCreditCardModal, chargeCreditCardDrawer, isEnabledManageCreditCards},
) => ({
  label: 'Charge credit card',
  onPress: () =>
    isEnabledManageCreditCards
      ? chargeCreditCardDrawer.handleOpen()
      : chargeCreditCardModal.handleOpen(),
  isDisabled: invoice.status === InvoiceStatus.FINALIZED,
});

const getManageCreditCardsAction = (invoice, {manageCreditCardsDrawer}) => ({
  label: 'Manage credit cards',
  onPress: () => manageCreditCardsDrawer.handleOpen(),
});

const getRecordPaymentAction = (invoice, {recordPaymentModal}) => ({
  label: 'Record payment',
  onPress: () => recordPaymentModal.handleOpen(),
  isDisabled: invoice.status === InvoiceStatus.FINALIZED,
});

const getCancelAction = (invoice, {cancelInvoiceModal}) => ({
  label: 'Cancel invoice',
  onPress: () => {
    cancelInvoiceModal.handleOpen();
  },
  isDisabled: _.includes([InvoiceStatus.PAID, InvoiceStatus.FINALIZED], invoice.status),
});

const getBillingSection = ({invoice, updateSalesTaxModal}) => {
  return [getEditSalesTaxAction(invoice, {updateSalesTaxModal})];
};

const getInvoiceSection = ({
  invoice,
  navigator,
  finalizeInvoiceModal,
  unfinalizeInvoiceModal,
  handleEditStorageInvoice,
  confirmInvoiceEditModal,
  updateInvoiceDrawer,
  handleResendInvoiceSubmit,
  cancelInvoiceModal,
  isAuthorizedAccountingActions,
}) => {
  const {
    project: {
      projectType: {category},
    },
    status,
  } = invoice;

  return [
    // View option
    getViewAction(invoice, {navigator}),

    // Finalize option
    ...List.insertIf(
      status !== InvoiceStatus.FINALIZED && isAuthorizedAccountingActions,
      getFinalizeAction(invoice, {finalizeInvoiceModal}),
    ),

    // Unfinalize option
    ...List.insertIf(
      status === InvoiceStatus.FINALIZED && isAuthorizedAccountingActions,
      getUnfinalizeAction(invoice, {unfinalizeInvoiceModal}),
    ),

    // Edit option
    ...List.insertIf(
      category === ProjectTypeCategory.STORAGE,
      getEditAction(invoice, {
        handleEditStorageInvoice,
        confirmInvoiceEditModal,
      }),
    ),

    // Send option
    ...List.insertIf(
      category === ProjectTypeCategory.MOVE && isAuthorizedAccountingActions,
      getSendAction(invoice, {updateInvoiceDrawer}),
    ),

    // Resend option
    ...List.insertIf(
      category === ProjectTypeCategory.STORAGE && isAuthorizedAccountingActions,
      getResendAction(invoice, {handleResendInvoiceSubmit}),
    ),

    // Cancel option
    ...List.insertIf(
      category === ProjectTypeCategory.STORAGE,
      getCancelAction(invoice, {cancelInvoiceModal}),
    ),
  ];
};

const getPaymentsSection = ({
  invoice,
  recordPaymentModal,
  chargeCreditCardModal,
  chargeCreditCardDrawer,
  manageCreditCardsDrawer,
}) => {
  const {
    project: {
      paymentsOrganization: {
        features: {isEnabledManageCreditCards},
      },
    },
  } = invoice;
  const isEnabledPayengine = Project.getIsEnabledCreditCards(invoice.project);

  return [
    // Record Payment option
    getRecordPaymentAction(invoice, {recordPaymentModal}),

    // Charge Credit Card option
    ...List.insertIf(
      !!isEnabledPayengine,
      getChargeCreditCardAction(invoice, {
        chargeCreditCardModal,
        chargeCreditCardDrawer,
        isEnabledManageCreditCards,
      }),
    ),

    // Manage Credit Cards option
    ...List.insertIf(
      !!isEnabledPayengine && isEnabledManageCreditCards,
      getManageCreditCardsAction(invoice, {manageCreditCardsDrawer}),
    ),
  ];
};

const getAdminSection = ({
  invoice,
  handleSendInvoiceToCodatSubmit,
  isAuthorizedAccountingActions,
}) => {
  const {codatIntegrations} = invoice.organization;

  return [
    // Export option
    ...List.insertIf(
      !_.isEmpty(codatIntegrations) && isAuthorizedAccountingActions,
      getExportAction(invoice, {handleSendInvoiceToCodatSubmit}),
    ),
  ];
};

// NOTE(cooper): This gets the possible actions that should populate in the invoice
// dropdown menu. Each invoice will have different options depending on the project
// and status of the invoice.
const getInvoiceActions = withFragment(
  (
    invoice,
    {
      handleEditStorageInvoice,
      handleSendInvoiceToCodatSubmit,
      handleResendInvoiceSubmit,
      recordPaymentModal,
      chargeCreditCardModal,
      chargeCreditCardDrawer,
      manageCreditCardsDrawer,
      cancelInvoiceModal,
      finalizeInvoiceModal,
      unfinalizeInvoiceModal,
      updateInvoiceDrawer,
      confirmInvoiceEditModal,
      navigator,
      updateSalesTaxModal,
      isAuthorizedAccountingActions,
    },
    visibleSections = ['BILLING', 'INVOICE', 'PAYMENTS', 'ADMIN'],
  ) => {
    const {isEnabledSalesTaxAdjustments} = invoice.organization.features;

    // No actions can be taken on a cancelled invoice
    if (invoice.status === InvoiceStatus.CANCELLED) {
      return [];
    }

    const billingSection =
      isEnabledSalesTaxAdjustments && _.includes(visibleSections, 'BILLING')
        ? getBillingSection({invoice, updateSalesTaxModal})
        : [];
    const invoiceSection = _.includes(visibleSections, 'INVOICE')
      ? getInvoiceSection({
          invoice,
          finalizeInvoiceModal,
          unfinalizeInvoiceModal,
          handleEditStorageInvoice,
          confirmInvoiceEditModal,
          updateInvoiceDrawer,
          handleResendInvoiceSubmit,
          cancelInvoiceModal,
          navigator,
          isAuthorizedAccountingActions,
        })
      : [];
    const paymentsSection = _.includes(visibleSections, 'PAYMENTS')
      ? getPaymentsSection({
          invoice,
          recordPaymentModal,
          chargeCreditCardModal,
          chargeCreditCardDrawer,
          manageCreditCardsDrawer,
        })
      : [];
    const adminSection = _.includes(visibleSections, 'ADMIN')
      ? getAdminSection({
          invoice,
          handleSendInvoiceToCodatSubmit,
          isAuthorizedAccountingActions,
        })
      : [];

    return [
      ...List.insertIf(_.some(billingSection), {
        label: 'Billing',
        actions: billingSection,
      }),
      ...List.insertIf(_.some(invoiceSection), {
        label: 'Invoice',
        actions: invoiceSection,
      }),
      ...List.insertIf(_.some(paymentsSection), {
        label: 'Payments',
        actions: paymentsSection,
      }),
      ...List.insertIf(_.some(adminSection), {
        label: 'Admin',
        actions: adminSection,
      }),
    ];
  },
  gql`
    ${Project.getIsEnabledCreditCards.fragment}

    fragment Invoice_getInvoiceActions on Invoice {
      id
      uuid
      status
      emailTemplateId
      organization {
        id
        slug
        codatIntegrations {
          id
        }
        features {
          isEnabledSalesTaxAdjustments: isEnabled(feature: "SALES_TAX_ADJUSTMENTS")
        }
      }
      project {
        id
        uuid
        isComplete
        projectType {
          id
          category
        }
        paymentsOrganization {
          id
          features {
            isEnabledManageCreditCards: isEnabled(feature: "MANAGE_CREDIT_CARDS")
          }
        }
        ...Project_getIsEnabledCreditCards
      }
    }
  `,
);

const Invoice = {
  getInvoiceActions,
};

export default Invoice;
