/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  background-color: ${(props) => (props.isSelected ? colors.blue.interactive : colors.white)};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Info = Styled.View`
  flex: 1;
  padding-right: 10px;
`;

const ProjectTypeColorBar = Styled.View`
  width: 6px;
  height: 100%;
  background-color: ${({color}) => color};
`;

const ProjectTypeName = Styled.Text`
  ${Typography.Label2}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : vars.color)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const ProjectName = Styled.Text`
  ${Typography.Body3}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const JobName = Styled.Text`
  ${Typography.Body1}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const TrainingName = Styled.Text`
  ${Typography.Body1}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.purple.hover)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const Description = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const Salesperson = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const getJobDetails = (job) => {
  const locationText = Job.getStartLocationTitle(job);
  if (!job.moveSize) {
    return locationText;
  }
  return `${locationText} - ${job.moveSize}`;
};

const getAdditionalSalespersonNames = ({project}) => {
  const names = project.additionalSalespersons.map((salesperson) => salesperson.fullName);
  return names.join(', ');
};

const JobListItem = ({isSelected, job}) => {
  const {navigator} = useNavigation();
  const {project, organization} = job;

  return (
    <Container isSelected={isSelected}>
      <Touchable
        disabled={job.isCancelled}
        onPress={() => navigator.navigate('ShowJob', {jobUuid: job.uuid})}
      >
        <Row>
          <ProjectTypeColorBar color={project.projectType.color} />
          <Space width={18} />
          <Info>
            <Space height={9} />
            <ProjectTypeName
              numberOfLines={2}
              vars={{isCancelled: job.isCancelled, color: project.projectType.color}}
            >
              {project.projectType.name}
            </ProjectTypeName>
            <Space height={1} />
            {organization.features.isEnabledProjectIdentifierField && (
              <React.Fragment>
                <ProjectName numberOfLines={1} vars={{isCancelled: job.isCancelled}}>
                  {Project.getDisplayText(project)}
                </ProjectName>
                <Space height={1} />
              </React.Fragment>
            )}
            <JobName numberOfLines={2} vars={{isCancelled: job.isCancelled}}>
              {Job.getJobNameForCrew(job)}
              {job.isTest && (
                <TrainingName vars={{isCancelled: job.isCancelled}}>{` (Training)`}</TrainingName>
              )}
            </JobName>
            <Space height={1} />
            <Description numberOfLines={1} vars={{isCancelled: job.isCancelled}}>
              {getJobDetails(job)}
            </Description>
            <Space height={1} />
            <Salesperson numberOfLines={1} vars={{isCancelled: job.isCancelled}}>
              {`Salesperson: ${project.bookedBy ? project.bookedBy.fullName : 'None'}`}
            </Salesperson>
            {project.additionalSalespersons.length > 0 && (
              <React.Fragment>
                <Space height={1} />
                <Salesperson numberOfLines={1} vars={{isCancelled: job.isCancelled}}>
                  {`${
                    organization.features.isEnabledProjectManagersField
                      ? 'Project Managers'
                      : 'Additional Salespersons'
                  }: ${getAdditionalSalespersonNames({project})}`}
                </Salesperson>
              </React.Fragment>
            )}
            <Space height={8} />
          </Info>
          <Space width={18} />
        </Row>
      </Touchable>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobListItem.fragment = gql`
  ${Job.getFullName.fragment}
  ${Job.getJobNameForCrew.fragment}
  ${Job.getStartLocationTitle.fragment}
  ${Project.getDisplayText.fragment}

  fragment JobListItem on Job {
    id
    uuid
    isCancelled
    isTest
    moveSize
    organization {
      id
      features {
        isEnabledProjectIdentifierField: isEnabled(feature: "PROJECT_IDENTIFIER_FIELD")
        isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
      }
    }
    project {
      id
      projectType {
        id
        name
        color
      }
      bookedBy {
        id
        fullName
      }
      additionalSalespersons {
        id
        fullName
      }
      customer {
        id
        phoneNumber
      }
      ...Project_getDisplayText
    }
    ...Job_getFullName
    ...Job_getJobNameForCrew
    ...Job_getStartLocationTitle
  }
`;

export default JobListItem;
