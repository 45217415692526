// Libraries
import React from 'react';

// Supermove
import {SupermoveApp, MaintenancePage} from '@supermove/app';
import {StatusBar, UpdateChecker} from '@supermove/components';
import {usePersistCache} from '@supermove/graphql';
import {PersistentStorage, Environment} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import {AppInfo, cache} from 'config';
import ErrorModal from 'modules/App/components/ErrorModal';

const AppContent = ({router: Router, handleError}) => {
  // We're using the max Storage size of 50MB.
  const maxSize = 1024 * 1024 * 50;
  const {isPersisted} = usePersistCache({cache, storage: PersistentStorage, maxSize});

  if (!isPersisted) {
    return null;
  }

  return (
    <SupermoveApp
      showOfflineOverlay={false}
      name={'Estimator'}
      version={AppInfo.getVersion()}
      buildNumber={AppInfo.getBuildNumber()}
      onError={() => handleError()}
    >
      {() => (
        <UpdateChecker showLoading name={'Estimator'} buildNumber={AppInfo.getBuildNumber()}>
          {({isSkipped, isVisible, isRequired, onSkip}) =>
            isVisible && !isSkipped ? (
              // TODO(mark): Show an update screen when the app has updates.
              <Router />
            ) : (
              <Router />
            )
          }
        </UpdateChecker>
      )}
    </SupermoveApp>
  );
};

const App = ({router}) => {
  return Environment.get('SHOW_MAINTENANCE_PAGE') ? (
    <MaintenancePage />
  ) : (
    <React.Fragment>
      <StatusBar barStyle={'light-content'} backgroundColor={colors.black} />
      <ErrorModal
        trigger={({handleOpen}) => <AppContent router={router} handleError={handleOpen} />}
      />
    </React.Fragment>
  );
};

export default App;
