// Supermove
import {gql} from '@supermove/graphql';
import {useFormMutation} from '@supermove/hooks';

// App
import SyncInventoryForm from '@shared/modules/Inventory/forms/SyncInventoryForm';

const useSyncInventoryMutation = ({form, onSuccess, onError}) => {
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSyncInventoryMutation.mutation,
    variables: {
      syncInventoryForm: SyncInventoryForm.toMutation(form.values.syncInventoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSyncInventoryMutation.mutation = gql`
  mutation useSyncInventoryMutation($syncInventoryForm: SyncInventoryForm!) {
    response: syncInventory(syncInventoryForm: $syncInventoryForm) {
      ${gql.errors}
    }
  }
`;

export default useSyncInventoryMutation;
