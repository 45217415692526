// Supermove
import {Icon} from '@supermove/components';

// App
import WorkflowRunStepActionType from '@shared/modules/Workflow/enums/WorkflowRunStepActionType';

// Project Actions
const ASSIGN_TO_BRANCH = 'ASSIGN_TO_BRANCH';
const GET_PROJECT_DATA = 'GET_PROJECT_DATA';
const INVOKE_WEBHOOK = 'INVOKE_WEBHOOK';

// Job Actions
const ASSIGN_TO_BRANCH_JOB = 'ASSIGN_TO_BRANCH_JOB';
const GET_JOB_DATA = 'GET_JOB_DATA';
const ASSIGN_OFFICE_STAFF = 'ASSIGN_OFFICE_STAFF';

// Task Actions
const CREATE_TASK = 'CREATE_TASK';
const COMPLETE_TASK = 'COMPLETE_TASK';
const ARCHIVE_TASK = 'ARCHIVE_TASK';
const UPDATE_TASK = 'UPDATE_TASK';
const FIND_TASK_FROM_PREVIOUS_STEP = 'FIND_TASK_FROM_PREVIOUS_STEP';

// Tag Actions
const PROJECT_ADD_TAG = 'PROJECT_ADD_TAG';
const JOB_ADD_TAG = 'JOB_ADD_TAG';
const PROJECT_REMOVE_TAG = 'PROJECT_REMOVE_TAG';
const JOB_REMOVE_TAG = 'JOB_REMOVE_TAG';

// Email Actions
const SEND_EMAIL = 'SEND_EMAIL';

// Control Actions
const DELAY = 'DELAY';
const STOP_IF = 'STOP_IF';

// SMS Actions
const SEND_SMS = 'SEND_SMS';
const SEND_SMS_V2 = 'SEND_SMS_V2';

const VALUES = [
  STOP_IF,
  DELAY,
  CREATE_TASK,
  COMPLETE_TASK,
  ARCHIVE_TASK,
  UPDATE_TASK,
  SEND_EMAIL,
  ASSIGN_OFFICE_STAFF,
  SEND_SMS,
  SEND_SMS_V2,
  GET_PROJECT_DATA,
  GET_JOB_DATA,
  FIND_TASK_FROM_PREVIOUS_STEP,
  ASSIGN_TO_BRANCH,
  ASSIGN_TO_BRANCH_JOB,
  INVOKE_WEBHOOK,
  PROJECT_ADD_TAG,
  JOB_ADD_TAG,
  PROJECT_REMOVE_TAG,
  JOB_REMOVE_TAG,
];

// Kind Groups
const CATEGORIES = {
  TASK: 'TASK',
  PROJECT: 'PROJECT',
  JOB: 'JOB',
  CONTROL: 'CONTROL',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
};

const KIND_TO_GROUP = {
  [CREATE_TASK]: CATEGORIES.TASK,
  [UPDATE_TASK]: CATEGORIES.TASK,
  [COMPLETE_TASK]: CATEGORIES.TASK,
  [ARCHIVE_TASK]: CATEGORIES.TASK,
  [FIND_TASK_FROM_PREVIOUS_STEP]: CATEGORIES.TASK,

  [GET_PROJECT_DATA]: CATEGORIES.PROJECT,

  [ASSIGN_TO_BRANCH_JOB]: CATEGORIES.JOB,
  [GET_JOB_DATA]: CATEGORIES.JOB,

  [STOP_IF]: CATEGORIES.CONTROL,
};

const GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES = {
  [CATEGORIES.TASK]: {
    label: 'Task Automation Step',
    placeholder: 'Select a automation step for a task',
    optionFilter: (workflowStepActionKind) =>
      KIND_TO_GROUP[workflowStepActionKind] === CATEGORIES.TASK,
  },
  [CATEGORIES.CONTROL]: {
    label: 'Automation Step',
    placeholder: 'Select a automation step to check against',
    optionFilter: (workflowStepActionKind) =>
      [CATEGORIES.TASK, CATEGORIES.PROJECT, CATEGORIES.JOB].includes(
        KIND_TO_GROUP[workflowStepActionKind],
      ),
  },
};

const ATTRIBUTES = {
  [ASSIGN_TO_BRANCH]: {
    value: ASSIGN_TO_BRANCH,
    label: 'Assign to Branch',
    icon: Icon.BuildingCircleArrowRight,
    description: 'Assign this project to a branch.',
    actionForm: 'assignToBranchActionForm',
    graphqlOperationName: 'assignProjectToBranch',
    kind: CATEGORIES.PROJECT,
  },
  [ASSIGN_TO_BRANCH_JOB]: {
    value: ASSIGN_TO_BRANCH_JOB,
    label: 'Assign Job to Branch',
    icon: Icon.BuildingCircleArrowRight,
    description: 'Assign this job to a branch.',
    actionForm: 'assignToBranchActionForm',
    graphqlOperationName: 'assignJobToBranch',
    kind: CATEGORIES.JOB,
  },
  [ASSIGN_OFFICE_STAFF]: {
    value: ASSIGN_OFFICE_STAFF,
    label: 'Assign Office Staff',
    icon: Icon.User,
    description: 'Assign this project to office staff.',
    actionForm: 'assignOfficeStaffActionForm',
    graphqlOperationName: 'assignOfficeUsersToProjectWorkflow',
    kind: CATEGORIES.PROJECT,
  },
  [CREATE_TASK]: {
    value: CREATE_TASK,
    label: 'Create Task',
    icon: Icon.FilePlus,
    description: 'Create a task using a task template.',
    actionForm: 'createTaskFromTemplateForm',
    graphqlOperationName: 'createTaskFromTemplate',
    kind: CATEGORIES.TASK,
  },
  [COMPLETE_TASK]: {
    value: COMPLETE_TASK,
    label: 'Complete Task',
    icon: Icon.FileCheck,
    description: 'Complete a task from this automation.',
    actionForm: 'toggleTaskPropertyActionForm',
    actionFormField: 'taskIdReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[COMPLETE_TASK]],
    graphqlOperationName: 'updateTaskIsCompleted',
    kind: CATEGORIES.TASK,
  },
  [ARCHIVE_TASK]: {
    value: ARCHIVE_TASK,
    label: 'Archive Task',
    icon: Icon.BoxArchive,
    description: 'Archive a task from this automation.',
    actionForm: 'toggleTaskPropertyActionForm',
    actionFormField: 'taskIdReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[ARCHIVE_TASK]],
    graphqlOperationName: 'updateTaskIsArchived',
    kind: CATEGORIES.TASK,
  },
  [UPDATE_TASK]: {
    value: UPDATE_TASK,
    label: 'Update Task',
    icon: Icon.Rotate,
    description: 'Update a task from this automation.',
    actionForm: 'updateTaskActionForm',
    actionFormField: 'taskIdReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[UPDATE_TASK]],
    graphqlOperationName: 'updateOneOrManyTaskProperties',
    kind: CATEGORIES.TASK,
  },
  [FIND_TASK_FROM_PREVIOUS_STEP]: {
    value: FIND_TASK_FROM_PREVIOUS_STEP,
    label: 'Get Task Data',
    icon: Icon.Search,
    actionForm: 'findTaskFromPreviousStepActionForm',
    actionFormField: 'taskIdReferencePath',
    description: 'Get task data from a previous step. The output can be used in future steps.',
    graphqlOperationName: 'taskById',
    workflowStepDropdown:
      GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[FIND_TASK_FROM_PREVIOUS_STEP]],
    kind: CATEGORIES.TASK,
  },
  [SEND_EMAIL]: {
    value: SEND_EMAIL,
    label: 'Send Email',
    icon: Icon.Envelope,
    actionForm: 'sendEmailFromTemplateActionForm',
    description:
      'Send an automated email using email templates. You will not see a record of this email unless your email address is included.',
    kind: CATEGORIES.EMAIL,
  },
  [DELAY]: {
    value: DELAY,
    label: 'Delay',
    icon: Icon.Clock,
    actionForm: 'delayWorkflowActionForm',
    kind: CATEGORIES.CONTROL,
  },
  [STOP_IF]: {
    value: STOP_IF,
    label: 'Stop If',
    icon: Icon.StopCircle,
    actionForm: 'stopIfActionForm',
    actionFormField: 'stepReferencePath',
    workflowStepDropdown: GROUP_TO_WORKFLOW_STEP_DROPDOWN_ATTRIBUTES[KIND_TO_GROUP[STOP_IF]],
    kind: CATEGORIES.CONTROL,
  },
  [GET_PROJECT_DATA]: {
    value: GET_PROJECT_DATA,
    label: 'Get project data',
    icon: Icon.Search,
    actionForm: 'getProjectDataActionForm',
    graphqlOperationName: 'projectById',
    description:
      'Get project data from the project this automation was triggered on. The output can be used in future steps.',
    kind: CATEGORIES.PROJECT,
  },
  [GET_JOB_DATA]: {
    value: GET_JOB_DATA,
    label: 'Get job data',
    icon: Icon.Search,
    actionForm: 'getJobDataActionForm',
    graphqlOperationName: 'jobById',
    description:
      'Get job data from the job this automation was triggered on. The output can be used in future steps.',
    kind: CATEGORIES.JOB,
  },
  [SEND_SMS]: {
    value: SEND_SMS,
    label: 'Send SMS to Customer',
    icon: Icon.CommentSms,
    actionForm: 'sendSmsFromTemplateActionForm',
    description: 'Send an automated SMS using SMS templates.',
    kind: CATEGORIES.SMS,
  },
  [SEND_SMS_V2]: {
    value: SEND_SMS_V2,
    label: 'Send SMS',
    icon: Icon.CommentSms,
    actionForm: 'sendSmsV2FromTemplateActionForm',
    description: 'Send an automated SMS using SMS templates.',
    kind: CATEGORIES.SMS,
  },
  [INVOKE_WEBHOOK]: {
    value: INVOKE_WEBHOOK,
    label: 'Invoke Webhook',
    icon: Icon.Bell,
    actionForm: 'invokeWebhookActionForm',
    description: 'Invoke a webhook.',
    kind: CATEGORIES.PROJECT,
  },
  [PROJECT_ADD_TAG]: {
    value: PROJECT_ADD_TAG,
    label: 'Add Project Tag(s)',
    icon: Icon.Tag,
    actionForm: 'updateTagAssignmentActionForm',
    description: 'Add a project tag.',
    kind: CATEGORIES.PROJECT,
  },
  [JOB_ADD_TAG]: {
    value: JOB_ADD_TAG,
    label: 'Add Job Tag(s)',
    icon: Icon.Tag,
    actionForm: 'updateTagAssignmentActionForm',
    description: 'Add a job tag.',
    kind: CATEGORIES.JOB,
  },
  [PROJECT_REMOVE_TAG]: {
    value: PROJECT_REMOVE_TAG,
    label: 'Remove Project Tag(s)',
    icon: Icon.Tag,
    actionForm: 'removeTagAssignmentActionForm',
    description: 'Remove a project tag.',
    kind: CATEGORIES.PROJECT,
  },
  [JOB_REMOVE_TAG]: {
    value: JOB_REMOVE_TAG,
    label: 'Remove Job Tag(s)',
    icon: Icon.Tag,
    actionForm: 'updateTagAssignmentActionForm',
    description: 'Remove a job tag.',
    kind: CATEGORIES.JOB,
  },
};

const WORKFLOW_STEP_ACTION_KIND_WITH_RUN_STEP_ACTION_TYPE = {
  [ASSIGN_TO_BRANCH]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[ASSIGN_TO_BRANCH],
  },
  [ASSIGN_TO_BRANCH_JOB]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[ASSIGN_TO_BRANCH_JOB],
  },
  [ASSIGN_OFFICE_STAFF]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[ASSIGN_OFFICE_STAFF],
  },
  [PROJECT_ADD_TAG]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[PROJECT_ADD_TAG],
  },
  [JOB_ADD_TAG]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[JOB_ADD_TAG],
  },
  [PROJECT_REMOVE_TAG]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[PROJECT_REMOVE_TAG],
  },
  [JOB_REMOVE_TAG]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[JOB_REMOVE_TAG],
  },
  [CREATE_TASK]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[CREATE_TASK],
  },
  [COMPLETE_TASK]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[COMPLETE_TASK],
  },
  [ARCHIVE_TASK]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[ARCHIVE_TASK],
  },
  [UPDATE_TASK]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[UPDATE_TASK],
  },
  [SEND_EMAIL]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[SEND_EMAIL],
  },
  [DELAY]: {
    [WorkflowRunStepActionType.WAIT_SECONDS]: ATTRIBUTES[DELAY],
  },
  [FIND_TASK_FROM_PREVIOUS_STEP]: {
    [WorkflowRunStepActionType.SEND_GRAPHQL_QUERY]: ATTRIBUTES[FIND_TASK_FROM_PREVIOUS_STEP],
  },
  [GET_PROJECT_DATA]: {
    [WorkflowRunStepActionType.SEND_GRAPHQL_QUERY]: ATTRIBUTES[GET_PROJECT_DATA],
  },
  [SEND_SMS]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[SEND_SMS],
  },
  [SEND_SMS_V2]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[SEND_SMS_V2],
  },
  [INVOKE_WEBHOOK]: {
    [WorkflowRunStepActionType.SEND_MUTATION]: ATTRIBUTES[INVOKE_WEBHOOK],
  },
  [STOP_IF]: {
    [WorkflowRunStepActionType.TRANSFORM]: {
      ...ATTRIBUTES[STOP_IF],
      label: 'Stop If: Evaluate Formula',
    },
    [WorkflowRunStepActionType.STOP]: {
      ...ATTRIBUTES[STOP_IF],
      label: 'Stop If: Stop',
    },
  },
};

const getWorkflowStepActionKindDisplayContent = ({workflowStepKind, workflowRunStepActionType}) => {
  return WORKFLOW_STEP_ACTION_KIND_WITH_RUN_STEP_ACTION_TYPE[workflowStepKind][
    workflowRunStepActionType
  ];
};

const WorkflowStepActionKind = {
  // Enum Options
  ASSIGN_TO_BRANCH,
  ASSIGN_TO_BRANCH_JOB,
  ASSIGN_OFFICE_STAFF,
  CREATE_TASK,
  COMPLETE_TASK,
  ARCHIVE_TASK,
  UPDATE_TASK,
  DELAY,
  STOP_IF,
  SEND_EMAIL,
  FIND_TASK_FROM_PREVIOUS_STEP,
  GET_PROJECT_DATA,
  GET_JOB_DATA,
  SEND_SMS,
  SEND_SMS_V2,
  INVOKE_WEBHOOK,
  PROJECT_ADD_TAG,
  JOB_ADD_TAG,
  PROJECT_REMOVE_TAG,
  JOB_REMOVE_TAG,

  // Enum Kind Groups
  CATEGORIES,

  VALUES,
  ATTRIBUTES,

  getWorkflowStepActionKindDisplayContent,
};

export default WorkflowStepActionKind;
