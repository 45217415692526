// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import SyncCollectionForm from '@shared/modules/Inventory/forms/SyncCollectionForm';
import SyncRoomForm from '@shared/modules/Inventory/forms/SyncRoomForm';

// Used for getting item counts on the estimator app. Estimator app will cache form data
// instead of immediately writing to the db and updating itself. We need to therefore
// manually handle rooms that get deleted on the ui because initially the room will get
// marked as isDeleted = true, but the form and data for the room will continue to persist
// on the frontend.
const _getSyncCollectionForms = ({syncRoomForms}) => {
  return _.compact(
    syncRoomForms.map((syncRoomForm) => !syncRoomForm.isDeleted && syncRoomForm.syncCollectionForm),
  );
};

// Used for getting item counts on the estimator app. See comment for _getSyncCollectionForms.
const getAllSyncItemForms = ({syncRoomForms}) => {
  const allSyncItemForms = syncRoomForms.map(
    (syncRoomForm) => !syncRoomForm.isDeleted && syncRoomForm.syncCollectionForm.syncItemForms,
  );
  return _.flatten(_.compact(allSyncItemForms));
};

const getLeaveCount = ({syncRoomForms}) => {
  return _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getLeaveCount);
};

const getTakeCount = ({syncRoomForms}) => {
  return _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getTakeCount);
};

const getTotalItemsCount = ({syncRoomForms}) => {
  return getLeaveCount({syncRoomForms}) + getTakeCount({syncRoomForms});
};

const getTotalVolume = ({syncRoomForms}) => {
  return _.round(
    _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getTotalVolume),
    2,
  );
};

const getTotalWeight = ({syncRoomForms}) => {
  return _.round(
    _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getTotalWeight),
    2,
  );
};

const edit = withFragment(
  (inventory) => ({
    inventoryId: inventory.id,
    uuid: inventory.uuid,
    densityFactor: inventory.densityFactor,
    syncRoomForms: inventory.rooms.map((room) => SyncRoomForm.edit(room)),

    // Private
    isEnabledInventoryPriceField:
      inventory.project.organization.features.isEnabledInventoryPriceField,
    lastSyncedAt: inventory.lastSyncedAt,
    syncCategoryForms: inventory.project.organization.categories.map((category) => ({
      categoryId: category.id,
      name: category.name,
      isShared: category.isShared,
      kind: category.kind,
    })),
    syncItemTypeForms: inventory.project.organization.itemTypes.map((itemType) => ({
      itemTypeId: itemType.id,
      name: itemType.name,
      weight: itemType.weight,
      volume: itemType.volume,
      price: itemType.price,
      isDerivedWeight: itemType.isDerivedWeight,
      densityFactor: inventory.densityFactor,
      categoryIds: itemType.itemTypeCategories.map((itemTypeCategory) => {
        return String(itemTypeCategory.categoryId);
      }),
      kind: itemType.kind,
    })),
    syncRoomTypeForms: inventory.project.organization.roomTypes.map((roomType) => ({
      roomTypeId: roomType.id,
      primaryCategoryId: roomType.primaryCategoryId,
      name: roomType.name,
    })),
  }),
  gql`
    ${SyncRoomForm.edit.fragment}

    fragment SyncInventoryForm_edit on Inventory {
      id
      uuid
      densityFactor
      lastSyncedAt
      project {
        id
        organization {
          id
          features {
            isEnabledInventoryPriceField: isEnabled(feature: "INVENTORY_PRICE_FIELD")
          }
          categories {
            id
            name
            isShared
            kind
          }
          itemTypes {
            id
            name
            weight
            volume
            price
            isDerivedWeight
            kind
            itemTypeCategories {
              id
              categoryId
            }
          }
          roomTypes {
            id
            primaryCategoryId
            name
          }
        }
      }
      rooms {
        id
        ...SyncRoomForm_edit
      }
    }
  `,
);

const _getDefaultSyncRoomForms = (syncInventoryForm) => {
  if (syncInventoryForm.syncRoomForms.length > 0) {
    return syncInventoryForm.syncRoomForms;
  }
  return [];
};

const load = withFragment(
  (inventory) => {
    const syncInventoryForm = edit(inventory);
    const syncRoomForms = _getDefaultSyncRoomForms(syncInventoryForm);

    return {
      ...syncInventoryForm,
      syncRoomForms,
    };
  },
  gql`
    ${edit.fragment}

    fragment SyncInventoryForm_load on Inventory {
      id
      ...SyncInventoryForm_edit
    }
  `,
);

const sync = withFragment(
  (syncInventoryForm) => ({
    __typename: 'SyncInventoryForm',
    id: syncInventoryForm.uuid,
    inventoryId: syncInventoryForm.inventoryId,
    uuid: syncInventoryForm.uuid,
    densityFactor: syncInventoryForm.densityFactor,
    syncRoomForms: syncInventoryForm.syncRoomForms.map((syncRoomForm) => {
      return SyncRoomForm.sync(syncRoomForm);
    }),

    // Private
    isEnabledInventoryPriceField: syncInventoryForm.isEnabledInventoryPriceField,
    lastSyncedAt: syncInventoryForm.lastSyncedAt,
    syncCategoryForms: syncInventoryForm.syncCategoryForms.map((syncCategoryForm) => ({
      __typename: 'SyncCategoryForm',
      id: syncCategoryForm.categoryId,
      categoryId: syncCategoryForm.categoryId,
      name: syncCategoryForm.name,
      isShared: syncCategoryForm.isShared,
      kind: syncCategoryForm.kind,
    })),
    syncItemTypeForms: syncInventoryForm.syncItemTypeForms.map((syncItemTypeForm) => ({
      __typename: 'SyncItemTypeForm',
      id: syncItemTypeForm.itemTypeId,
      itemTypeId: syncItemTypeForm.itemTypeId,
      name: syncItemTypeForm.name,
      weight: syncItemTypeForm.weight,
      volume: syncItemTypeForm.volume,
      price: syncItemTypeForm.price,
      isDerivedWeight: syncItemTypeForm.isDerivedWeight,
      densityFactor: syncItemTypeForm.densityFactor,
      categoryIds: syncItemTypeForm.categoryIds,
      kind: syncItemTypeForm.kind,
    })),
    syncRoomTypeForms: syncInventoryForm.syncRoomTypeForms.map((syncRoomTypeForm) => ({
      __typename: 'SyncRoomTypeForm',
      id: syncRoomTypeForm.roomTypeId,
      roomTypeId: syncRoomTypeForm.roomTypeId,
      primaryCategoryId: syncRoomTypeForm.primaryCategoryId,
      name: syncRoomTypeForm.name,
    })),
  }),
  gql`
    ${SyncRoomForm.sync.fragment}

    fragment SyncInventoryForm_sync on SyncInventoryForm {
      id
      inventoryId
      uuid
      densityFactor
      lastSyncedAt
      isEnabledInventoryPriceField
      syncRoomForms {
        ...SyncRoomForm_sync
      }
      syncCategoryForms {
        id
        categoryId
        name
        isShared
        kind
      }
      syncItemTypeForms {
        id
        itemTypeId
        name
        weight
        volume
        price
        isDerivedWeight
        densityFactor
        categoryIds
        kind
      }
      syncRoomTypeForms {
        id
        roomTypeId
        primaryCategoryId
        name
      }
    }
  `,
);

const _new = () => ({
  inventoryId: null,
  uuid: null,
  densityFactor: 0,
  syncRoomForms: [],

  // Private
  isEnabledInventoryPriceField: false,
  lastSyncedAt: null,
  syncCategoryForms: [],
  syncItemTypeForms: [],
  syncRoomTypeForms: [],
});

const toForm = ({
  inventoryId,
  uuid,
  densityFactor,
  syncRoomForms,

  // Private
  isEnabledInventoryPriceField,
  lastSyncedAt,
  syncCategoryForms,
  syncItemTypeForms,
  syncRoomTypeForms,
}) => ({
  inventoryId,
  uuid,
  densityFactor,
  syncRoomForms: syncRoomForms.map((syncRoomForm) => SyncRoomForm.toForm(syncRoomForm)),

  // Private
  isEnabledInventoryPriceField,
  lastSyncedAt,
  syncCategoryForms,
  syncItemTypeForms,
  syncRoomTypeForms,
});

const toMutation = ({inventoryId, densityFactor, syncRoomForms}) => ({
  inventoryId,
  densityFactor,
  syncRoomForms: syncRoomForms.map((syncRoomForm) => SyncRoomForm.toMutation(syncRoomForm)),
});

const SyncInventoryForm = {
  // Initialize
  new: _new,
  edit,
  load,

  // Methods
  sync,

  // Getters
  getAllSyncItemForms,
  getLeaveCount,
  getTakeCount,
  getTotalItemsCount,
  getTotalVolume,
  getTotalWeight,

  // Serialize
  toForm,
  toMutation,
};

export default SyncInventoryForm;
