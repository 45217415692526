/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useForm, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncRoomForm from '@shared/modules/Inventory/forms/SyncRoomForm';
import Modal from 'components/Modal';
import Field from 'modules/App/components/Field';

const Container = Styled.View`
  padding: 20px;
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const EditRoomModal = ({
  isOpen,
  syncRoomForm,
  handleOpen,
  handleClose,
  handleSubmit,
  handleDelete,
}) => {
  const responsive = useResponsive();
  const form = useForm({
    initialValues: {
      syncRoomForm: SyncRoomForm.toForm(SyncRoomForm.copy(syncRoomForm)),
    },
    validate: ({syncRoomForm}) => SyncRoomForm.validate({prefix: 'syncRoomForm', syncRoomForm}),
    onSubmit: ({syncRoomForm}) => handleSubmit({syncRoomForm}),
  });

  return (
    <Modal
      isOpen={isOpen}
      title={'Edit Room Details'}
      subtitle={syncRoomForm.name}
      onClose={handleClose}
    >
      <Container>
        <Field
          {...form}
          name={'syncRoomForm.name'}
          label={'Room Name'}
          input={{
            required: !form.values.syncRoomForm.name,
            autoFocus: true,
            placeholder: 'Enter a custom room name',
            style: {
              width: responsive.mobile ? '100%' : 360,
            },
          }}
          style={{
            width: responsive.mobile ? '100%' : 360,
          }}
        />
        <Space height={20} />
        <Field
          {...form}
          name={'syncRoomForm.floorNumber'}
          label={'Floor'}
          input={{
            placeholder: '#',
            style: {
              width: responsive.mobile ? '100%' : 360,
            },
          }}
          style={{
            width: responsive.mobile ? '100%' : 360,
          }}
        />
        <Space height={20} />
        <Field
          {...form}
          name={'syncRoomForm.description'}
          label={'Additional Room Notes'}
          input={{
            placeholder: 'Enter additional notes',
            style: {
              width: responsive.mobile ? '100%' : 360,
            },
          }}
          style={{
            width: responsive.mobile ? '100%' : 360,
          }}
        />
        <Space height={20} />
        <Actions>
          <Button color={colors.blue.interactive} onPress={() => form.submitForm()}>
            <Text color={colors.white}>Save Changes</Text>
          </Button>
        </Actions>
        <Space height={20} />
        <Line />
        <Space height={20} />
        <Actions>
          <Button
            color={colors.red.accent}
            onPress={() => {
              handleClose();
              handleDelete();
            }}
          >
            <Text color={colors.red.warning}>Delete Room</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

export default EditRoomModal;
