/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import Modal from 'components/Modal';

const Container = Styled.View`
  padding: 20px;
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.tertiary};
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const SyncInventoryFormOfflineModal = ({isOpen, handleClose}) => {
  return (
    <Modal isOpen={isOpen} title={'You are offline'} subtitle={''} onClose={handleClose}>
      <Container>
        <Subtitle>
          {"Your device is currently offline and we're unable to save your estimate. " +
            'Once you are online, your estimate will automatically save.'}
        </Subtitle>
        <Space height={20} />
        <Actions>
          <Button color={colors.blue.interactive} onPress={handleClose}>
            <Text color={colors.white}>Got It</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

export default SyncInventoryFormOfflineModal;
