/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncCollectionForm from '@shared/modules/Inventory/forms/SyncCollectionForm';

const Container = Styled.View`
  flex-direction: row;
`;

const Section = Styled.View`
  width: 50px;
  align-items: flex-end;
`;

const Title = Styled.H8`
  font-size: 8px;
  color: ${colors.gray.tertiary};
  text-transform: uppercase;
`;

const Count = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const SyncItemFormsHeader = ({syncItemForms}) => {
  return (
    <Container>
      <Section>
        <Title>Take</Title>
        <Count>{SyncCollectionForm.getTakeCount({syncItemForms})}</Count>
      </Section>
      <Section>
        <Title>Leave</Title>
        <Count>{SyncCollectionForm.getLeaveCount({syncItemForms})}</Count>
      </Section>
      <Section>
        <Title>CU FT</Title>
        <Count>{SyncCollectionForm.getTotalVolume({syncItemForms})}</Count>
      </Section>
      <Section>
        <Title>LB</Title>
        <Count>{SyncCollectionForm.getTotalWeight({syncItemForms})}</Count>
      </Section>
    </Container>
  );
};

export default SyncItemFormsHeader;
