/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import NewCustomItemModal from 'modules/Item/components/NewCustomItemModal';

const Container = Styled.View`
  padding-vertical: 10px;
  background-color: ${colors.white};
`;

const Item = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  padding-vertical: 11px;
  padding-horizontal: 15px;
  background-color: ${(props) => (props.isSelected ? colors.blue.accent : colors.white)};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 20px;
`;

const Name = Styled.Text`
  font-size: 10px;
  line-height: 14px;
  ${({vars = {}}) => (vars.isSelected ? fontWeight(700) : fontWeight(500))}
  color: ${({vars = {}}) => (vars.isSelected ? colors.blue.accentDark : colors.gray.primary)};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FilterItem = ({isSelected, name, source, onSelect}) => {
  return (
    <Item isSelected={isSelected} onPress={onSelect}>
      {!!source && (
        <React.Fragment>
          <Icon
            color={isSelected ? colors.blue.accentDark : colors.gray.primary}
            size={10}
            source={source}
          />
          <Space width={6} />
        </React.Fragment>
      )}
      <Name vars={{isSelected}}>{name}</Name>
    </Item>
  );
};

const SyncCategoryFormItem = ({isSelected, isPrimary, isCarton, syncCategoryForm, onSelect}) => {
  return (
    <FilterItem
      isSelected={isSelected}
      name={syncCategoryForm.name}
      source={isPrimary ? Icon.Star : isCarton ? Icon.BoxOpen : null}
      onSelect={onSelect}
    />
  );
};

const getFilteredSyncCategoryForms = ({primaryCategoryId, syncInventoryForm}) => {
  const syncCategoryForms = syncInventoryForm.syncCategoryForms.filter((syncCategoryForm) => {
    return syncCategoryForm.isShared || syncCategoryForm.categoryId === primaryCategoryId;
  });

  return _.orderBy(
    syncCategoryForms,
    [
      (syncCategoryForm) => syncCategoryForm.kind === 'CARTON',
      (syncCategoryForm) => syncCategoryForm.categoryId === primaryCategoryId,
      (syncCategoryForm) => syncCategoryForm.name,
    ],
    [
      // Show cartons first.
      'desc',

      // Show the primary category next.
      'desc',

      // Then show the rest of the categories ordered by name in alphabetical order.
      'asc',
    ],
  );
};

const getSelectedCategoryName = ({syncCategoryForms, selectedCategoryId}) => {
  const selectedSyncCategoryForm = _.find(syncCategoryForms, (syncCategoryForm) => {
    return String(syncCategoryForm.categoryId) === String(selectedCategoryId);
  });

  return selectedSyncCategoryForm ? selectedSyncCategoryForm.name : 'Surveyed';
};

const Header = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: 20px
  align-items: center;
`;

const HeaderTitle = Styled.Text`
  font-size: 10px;
  line-height: 14px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const SelectedCategoryTitle = Styled.Text`
  font-size: 10px;
  line-height: 14px;
  ${fontWeight(700)}
  margin-left: 4px;
  color: ${colors.blue.accentDark};
  text-transform: capitalize;
`;

const Touchable = Styled.Touchable`
`;

const CreateLink = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const SyncRoomFormCategoriesHeader = ({
  selectedCategoryId,
  syncRoomField,
  syncCategoryForms,
  form,
}) => {
  const newCustomItemModal = useModal({name: 'NewCustomItemModal'});
  const selectedCategoryName = getSelectedCategoryName({syncCategoryForms, selectedCategoryId});

  return (
    <React.Fragment>
      <Header>
        <HeaderTitle>
          Category:
          <SelectedCategoryTitle>{selectedCategoryName}</SelectedCategoryTitle>
        </HeaderTitle>
        <Touchable onPress={newCustomItemModal.handleOpen}>
          <CreateLink>+ Add Custom Item</CreateLink>
        </Touchable>
      </Header>
      <NewCustomItemModal
        key={newCustomItemModal.key}
        isOpen={newCustomItemModal.isOpen}
        syncRoomField={syncRoomField}
        form={form}
        handleClose={newCustomItemModal.handleClose}
      />
    </React.Fragment>
  );
};

const SyncRoomFormCategoriesList = ({syncRoomField, syncRoomForm, form, setSelectedCategoryId}) => {
  const {syncInventoryForm} = form.values;
  const primaryCategoryId = _.toString(syncRoomForm.primaryCategoryId);
  const selectedCategoryId = _.toString(syncRoomForm.selectedCategoryId);
  const syncCategoryForms = getFilteredSyncCategoryForms({primaryCategoryId, syncInventoryForm});

  return (
    <Container>
      <SyncRoomFormCategoriesHeader
        selectedCategoryId={selectedCategoryId}
        syncRoomField={syncRoomField}
        syncCategoryForms={syncCategoryForms}
        form={form}
      />
      <Space height={10} />
      <FlatList
        horizontal
        data={syncCategoryForms}
        keyExtractor={(syncCategoryForm) => syncCategoryForm.categoryId}
        renderItem={({item: syncCategoryForm, index}) => (
          <React.Fragment>
            <SyncCategoryFormItem
              isPrimary={primaryCategoryId === syncCategoryForm.categoryId}
              isCarton={syncCategoryForm.kind === 'CARTON'}
              isSelected={selectedCategoryId === syncCategoryForm.categoryId}
              syncCategoryForm={syncCategoryForm}
              onSelect={() => setSelectedCategoryId(syncCategoryForm.categoryId)}
            />
            <Space width={10} />
          </React.Fragment>
        )}
        ListHeaderComponent={() => (
          <Row>
            <FilterItem
              isSelected={!selectedCategoryId}
              name={'Surveyed'}
              source={Icon.Check}
              onSelect={() => setSelectedCategoryId(null)}
            />
            <Space width={10} />
          </Row>
        )}
        contentContainerStyle={{
          paddingHorizontal: 20,
        }}
      />
    </Container>
  );
};

export default SyncRoomFormCategoriesList;
