// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal as BaseModal, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const WIDTH = {
  LARGE: 1000,
  MEDIUM: 800,
  SMALL: 400,
};

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-vertical: ${({mobile}) => (mobile ? '0px' : '40px')};
`;

const ContentContainer = Styled.View`
  background-color: ${colors.white};
  overflow: hidden;
  ${(props) => props.isResponsive && 'max-width: 100%; max-height: 100%;'}
  ${(props) =>
    props.isResponsive && props.mobile
      ? 'width: 100%; height: 100%;'
      : `width: ${props.width}px; border-radius: 4px;`}
`;

const BodyContainer = Styled.View`
  background-color: ${colors.gray.background};
  padding-horizontal: 24px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
`;

const BoldText = Styled.Text`
  ${Typography.Label1}
`;

const Text = Styled.Text`
  ${Typography.Body2}
`;

const Block = Styled.View`
  z-index: ${({index}) => 100 - index};
  padding: 24px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const BlockHeader = Styled.Text`
  ${Typography.Label1}
`;

const ButtonContainer = Styled.ButtonV2`
  min-height: 40px;
  min-width: 152px;
  padding-horizontal: 16px;
  padding-vertical: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  padding-horizontal: 24px;
  padding-top: 24px;
  padding-bottom: 16px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 24px;
  padding-top: 16px;
  padding-bottom: 24px;
`;

const ActivityIndicator = Styled.Loading`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const View = Styled.View`
`;

const _getButtonColor = ({isDisabled, isSubmitting, color}) => {
  if (color) {
    if (isDisabled || isSubmitting) {
      return colors.gray.border;
    }
    return color;
  }
  return colors.white;
};

const PreventPropagationContainer = ({children, style}) => {
  return (
    <View
      onStartShouldSetResponder={(event) => true}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

const ButtonIcon = ({icon, size, color, style}) => {
  return <Icon source={icon} size={size} color={color} style={style} />;
};

const Button = ({
  children,
  color,
  onPress,
  isSubmitting,
  isDisabled,
  style,
  iconLeft,
  iconSize,
}) => {
  return (
    <ButtonContainer
      color={_getButtonColor({isDisabled, isSubmitting, color})}
      onPress={onPress}
      disabled={isDisabled || isSubmitting}
      style={style}
    >
      {isSubmitting ? (
        <ActivityIndicator size={'small'} color={color ? colors.white : colors.gray.secondary} />
      ) : (
        <Row style={{alignItems: 'center'}}>
          {iconLeft && (
            <ButtonIcon
              icon={iconLeft}
              color={color ? colors.white : colors.gray.secondary}
              size={iconSize}
            />
          )}
          {iconLeft && children && <Space width={8} />}
          <ButtonText color={color ? colors.white : colors.gray.secondary}>{children}</ButtonText>
        </Row>
      )}
    </ButtonContainer>
  );
};

const Header = ({children, style}) => {
  return <HeaderContainer style={style}>{children}</HeaderContainer>;
};

const Body = ({children, style, scrollRef, isScrollable}) => {
  if (isScrollable) {
    return (
      <ScrollView
        style={{
          backgroundColor: colors.gray.background,
          padding: 24,
          ...style,
        }}
        ref={scrollRef}
      >
        {children}
      </ScrollView>
    );
  }
  return (
    <BodyContainer style={style}>
      <Space height={24} />
      {children}
      <Space height={24} />
    </BodyContainer>
  );
};

const Footer = ({children, style}) => {
  return <FooterContainer style={style}>{children}</FooterContainer>;
};

const Modal = ({
  isOpen,
  handlePressOutside,
  children,
  width,
  isResponsive,
  style,
  screenContainerStyle,
}) => {
  const responsive = useResponsive();

  return (
    // BaseModal.Content requires an onClose prop. However, the only functionality for the function
    // is to handle when pressing outside of the modal. Here we have a default prop that we always
    // pass in which effectively does nothing when pressing outside of the modal. We can then
    // optionally pass in any logic with handlePressOutside to take place when pressing outside of
    // the modal, whether it be to simply close the modal or more.
    <BaseModal.Content isOpen={isOpen} onClose={handlePressOutside}>
      <ScreenContainer {...responsive} pointerEvents={'box-none'} style={screenContainerStyle}>
        {/*
          When restricting the height of the modal, pass in style={{flex: 1}}. Otherwise, the height
          of the modal will be dynamic to the content, and if there is a lot of vertical content it
          will expand off the view.
        */}
        <ContentContainer width={width} {...responsive} isResponsive={isResponsive} style={style}>
          {children}
        </ContentContainer>
      </ScreenContainer>
    </BaseModal.Content>
  );
};

const Responsive = (props) => <Modal {...props} isResponsive />;

Modal.HeaderText = HeaderText;
Modal.BoldText = BoldText;
Modal.Text = Text;
Modal.Button = Button;
Modal.ButtonText = ButtonText;
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Block = Block;
Modal.BlockHeader = BlockHeader;
Modal.Responsive = Responsive;
Modal.WIDTH = WIDTH;
Modal.PreventPropagationContainer = PreventPropagationContainer;

// --------------------------------------------------
// Props
// --------------------------------------------------
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handlePressOutside: PropTypes.func,
  width: PropTypes.number,
  isResponsive: PropTypes.bool,
  style: PropTypes.object,
  screenContainerStyle: PropTypes.object,
};

Modal.defaultProps = {
  handlePressOutside: () => {},
  width: null,
  isResponsive: false,
  style: null,
  screenContainerStyle: null,
};

Button.propTypes = {
  color: PropTypes.string,
  onPress: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  iconSize: PropTypes.number,
};

Button.defaultProps = {
  color: null,
  onPress: () => {},
  isSubmitting: false,
  isDisabled: false,
  style: null,
  iconLeft: null,
  iconSize: 10,
};

Block.propTypes = {
  index: PropTypes.number,
};

Block.defaultProps = {
  index: 0,
};

export default Modal;
