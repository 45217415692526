// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';

const Container = Styled.View`
`;

const IconContainer = Styled.View`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({color}) => colors.getBackgroundColor(color)};
`;

const Title = Styled.Text`
  ${Typography.Heading2}
  color: ${({color}) => color};
`;

const FooterContainer = Styled.View`
  flex-direction: ${({isResponsive, mobile}) =>
    isResponsive && mobile ? 'column-reverse' : 'row'};
  justify-content: flex-end;
  width: 100%;
`;

const LoadingContainer = Styled.View`
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LoadingIndicator = () => (
  <LoadingContainer>
    <Space height={36} />
    <Indicator size={'large'} color={colors.gray.secondary} />
    <Space height={36} />
  </LoadingContainer>
);

const HeaderIcon = ({source, color, label, style}) => {
  return (
    <Container style={style}>
      <IconContainer color={color}>
        <Icon source={source} color={color} size={18} />
      </IconContainer>
      {label && (
        <React.Fragment>
          <Space height={20} />
          <Title color={color}>{label}</Title>
        </React.Fragment>
      )}
    </Container>
  );
};

const Footer = ({children, style, isResponsive}) => {
  const responsive = useResponsive();

  return (
    <FooterContainer {...responsive} isResponsive={isResponsive} style={style}>
      {children}
    </FooterContainer>
  );
};

const SmallModal = ({isOpen, isLoading, handlePressOutside, style, isResponsive, children}) => {
  const responsive = useResponsive();

  return (
    <Modal
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      style={{
        width: isResponsive && responsive.mobile ? '100%' : Modal.WIDTH.SMALL,
        maxWidth: Modal.WIDTH.SMALL,
        padding: isResponsive && !responsive.desktop ? 16 : 24,
        ...style,
      }}
      screenContainerStyle={{padding: 16}}
    >
      {isLoading ? <LoadingIndicator /> : children}
    </Modal>
  );
};

SmallModal.HeaderIcon = HeaderIcon;
SmallModal.HeaderText = Modal.HeaderText;
SmallModal.BoldText = Modal.BoldText;
SmallModal.Text = BodyText;
SmallModal.Button = Modal.Button;
SmallModal.ButtonText = Modal.ButtonText;
SmallModal.Footer = Footer;
SmallModal.PreventPropagationContainer = Modal.PreventPropagationContainer;

// --------------------------------------------------
// Props
// --------------------------------------------------
SmallModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isResponsive: PropTypes.bool,
  handlePressOutside: PropTypes.func,
  style: PropTypes.object,
};

SmallModal.defaultProps = {
  isLoading: false,
  isResponsive: false,
  handlePressOutside: () => {},
  style: {},
};

HeaderIcon.propTypes = {
  source: Icon.SourcePropType.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
};

HeaderIcon.defaultProps = {
  color: null,
  label: null,
  style: null,
};

Footer.propTypes = {
  style: PropTypes.object,
};

Footer.defaultProps = {
  style: null,
};

export default SmallModal;
