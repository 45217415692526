// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import CurrentSyncInventoryFormProvider from 'modules/App/components/CurrentSyncInventoryFormProvider';
import UserAnalyticsTracker from 'modules/App/components/UserAnalyticsTracker';

const AuthenticatedWrapper = ({children}) => {
  const {loading, data} = useQuery(AuthenticatedWrapper.query, {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return null;
  }

  return (
    <React.Fragment>
      <UserAnalyticsTracker viewer={data.viewer} />
      <CurrentSyncInventoryFormProvider>{children}</CurrentSyncInventoryFormProvider>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthenticatedWrapper.query = gql`
  ${UserAnalyticsTracker.fragment}

  query AuthenticatedWrapper {
    ${gql.query}
    viewer {
      id
      ...UserAnalyticsTracker
    }
  }
`;

export default AuthenticatedWrapper;
