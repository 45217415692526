/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const ItemTouchable = Styled.Touchable`
  height: 45px;
  background-color: ${(props) => (props.isSelected ? colors.white : colors.transparent)};
`;

const Inner = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Bar = Styled.View`
  width: 3px;
  background-color: ${(props) => (props.isSelected ? colors.blue.accentDark : colors.transparent)};
`;

const Center = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Text = Styled.H8`
  color: ${colors.gray.primary};
`;

const SidebarItem = ({isFirst, isSelected, disabled, icon, text, onPress, children}) => {
  return (
    <React.Fragment>
      <ItemTouchable disabled={disabled} isSelected={isSelected} onPress={onPress}>
        {isFirst && <Line />}
        <Inner>
          <Bar isSelected={isSelected} />
          <Center>
            <Space width={10} />
            <Icon color={colors.gray.primary} size={16} source={icon} />
            <Space width={10} />
            <Text>{text}</Text>
          </Center>
        </Inner>
      </ItemTouchable>
      {children}
      <Line />
    </React.Fragment>
  );
};

const SubitemTouchable = Styled.Touchable`
  margin-right: 10px;
  height: 35px;
  background-color: ${(props) => (props.isSelected ? colors.blue.accent : colors.white)};
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
`;

const SidebarSubitem = ({isSelected, text, onPress}) => {
  return (
    <SubitemTouchable isSelected={isSelected} onPress={onPress}>
      <Inner>
        <Center>
          <Space width={25} />
          <Text>{text}</Text>
        </Center>
      </Inner>
    </SubitemTouchable>
  );
};

const Container = Styled.View`
  width: 150px;
  background-color: ${colors.gray.background};
  border-right-width: 1px;
  border-right-color: ${colors.gray.border};
`;

const Sidebar = ({children}) => {
  return <Container>{children}</Container>;
};

Sidebar.Item = SidebarItem;
Sidebar.Subitem = SidebarSubitem;

export default Sidebar;
