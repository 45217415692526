// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Message = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const ValidationError = ({name, touched, errors}) => {
  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  if (!isTouched || !error) {
    return null;
  }

  return <Message>{error}</Message>;
};

export default ValidationError;
