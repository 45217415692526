/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import SyncRoomForm from '@shared/modules/Inventory/forms/SyncRoomForm';
import Modal from 'components/Modal';
import NewCustomRoomModal from 'modules/Room/components/NewCustomRoomModal';

const Container = Styled.View`
  padding-top: 20px;
  height: 400px;
`;

const Item = Styled.Touchable`
  padding-horizontal: 20px;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const RoomTypeItem = ({syncRoomTypeForm, onSelect}) => {
  return (
    <Item onPress={() => onSelect(syncRoomTypeForm)}>
      <Name>{syncRoomTypeForm.name}</Name>
    </Item>
  );
};

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const NewRoomModal = ({isOpen, syncInventoryForm, handleClose, handleSubmit}) => {
  const newCustomRoomModal = useModal({name: 'NewCustomRoomModal'});
  const handleSelect = async (syncRoomTypeForm) => {
    const syncRoomForm = SyncRoomForm.new({
      inventoryId: syncInventoryForm.inventoryId,
      roomTypeId: syncRoomTypeForm.roomTypeId,
      name: syncRoomTypeForm.name,
      syncItemForms: syncInventoryForm.syncItemTypeForms.map((syncItemTypeForm) => {
        return SyncItemForm.toForm(SyncItemForm.newForSyncItemTypeForm(syncItemTypeForm));
      }),
      primaryCategoryId: syncRoomTypeForm.primaryCategoryId,
    });

    handleClose();
    await handleSubmit({syncRoomForm});
  };

  const handleNewCustomRoom = async ({syncRoomForm}) => {
    newCustomRoomModal.handleClose();
    await handleSubmit({syncRoomForm});
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        title={'Add New Room'}
        subtitle={'Add a room to start collecting inventory.'}
        onClose={handleClose}
      >
        <Container>
          <Line />
          <FlatList
            data={syncInventoryForm.syncRoomTypeForms}
            keyExtractor={(syncRoomTypeForm) => syncRoomTypeForm.roomTypeId}
            renderItem={({item: syncRoomTypeForm, index}) => (
              <React.Fragment>
                <RoomTypeItem syncRoomTypeForm={syncRoomTypeForm} onSelect={handleSelect} />
                <Line />
              </React.Fragment>
            )}
            ListFooterComponent={() => (
              <Item
                onPress={() => {
                  handleClose();
                  newCustomRoomModal.handleOpen();
                }}
              >
                <Name>Custom Room</Name>
              </Item>
            )}
          />
        </Container>
      </Modal>
      <NewCustomRoomModal
        key={newCustomRoomModal.key}
        isOpen={newCustomRoomModal.isOpen}
        syncInventoryForm={syncInventoryForm}
        handleClose={newCustomRoomModal.handleClose}
        handleSubmit={handleNewCustomRoom}
      />
    </React.Fragment>
  );
};

export default NewRoomModal;
