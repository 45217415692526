/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useEffect, useNavigation, useQuery} from '@supermove/hooks';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const HomePage = () => {
  const {navigator} = useNavigation();
  const {loading, data} = useQuery(HomePage.query, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    if (data.viewer) {
      navigator.replace('ListJobs');
    } else {
      navigator.navigate('Start');
    }
  }, [loading, data, navigator]);

  return <PageLoadingIndicator />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
HomePage.query = gql`
  query HomePage {
    ${gql.query}
    viewer {
      id
    }
  }
`;

export default HomePage;
