// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ExternalLink, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {DeviceInfo} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import {AppInfo} from 'config';

const Container = Styled.View`
`;

const BackButton = Styled.ButtonV2`
  padding: 20px;
`;

const SectionContainer = Styled.View`
  margin-horizontal: 20px;
  padding-vertical: 20px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const Title = Styled.Text`
  ${Typography.Heading5}
`;

const PrivacyPolicyText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.blue.interactive};
`;

const Section = ({title, children}) => {
  return (
    <SectionContainer>
      <Title>{title}</Title>
      <Space height={10} />
      {children}
    </SectionContainer>
  );
};

const SettingsPageContent = ({data}) => {
  const {navigator} = useNavigation();
  return (
    <Container>
      <BackButton onPress={() => navigator.goBack()}>
        <Icon source={Icon.ArrowLeft} size={16} color={colors.gray.primary} />
      </BackButton>
      <Section title={'Organization'}>
        <FieldValue label={'Name'} value={data.viewer.organization.name} />
      </Section>
      <Section title={'Device'}>
        <FieldValue label={'Name'} value={_.get(data, 'device.name')} />
      </Section>
      <Section title={'App'}>
        <FieldValue label={'Version'} value={AppInfo.getVersion()} />
        <Space height={10} />
        <FieldValue label={'Build Number'} value={AppInfo.getBuildNumber()} />
      </Section>
      <Section title={'Supermove'}>
        <ExternalLink url={`https://supermove.co/privacy-policy`}>
          <PrivacyPolicyText>Privacy Policy</PrivacyPolicyText>
        </ExternalLink>
      </Section>
    </Container>
  );
};

const SettingsPage = () => {
  const {loading, data} = useQuery(SettingsPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uniqueId: DeviceInfo.getUniqueId(),
    },
  });
  return <Loading loading={loading}>{() => <SettingsPageContent data={data} />}</Loading>;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SettingsPage.query = gql`
  query SettingsPage($uniqueId: String!) {
    viewer {
      id
      organization {
        id
        name
      }
    }
    device(uniqueId: $uniqueId) {
      id
      name
    }
  }
`;

export default SettingsPage;
