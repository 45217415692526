const SEND_MUTATION = 'SEND_MUTATION';
const WAIT_UNTIL = 'WAIT_UNTIL';
const WAIT_SECONDS = 'WAIT_SECONDS';
const TRANSFORM = 'TRANSFORM';
const STOP = 'STOP';
const SEND_GRAPHQL_QUERY = 'SEND_GRAPHQL_QUERY';

const WorkflowRunStepActionType = {
  // Enum Options
  SEND_MUTATION,
  WAIT_UNTIL,
  WAIT_SECONDS,
  TRANSFORM,
  STOP,
  SEND_GRAPHQL_QUERY,
};

export default WorkflowRunStepActionType;
