// Libraries
import React from 'react';

// Supermove
import {useCodePushUpdater, useIsFocused} from '@supermove/hooks';

// App
import CodePushModal from 'modules/Settings/CodePush/components/CodePushModal';

const SettingsCodePushPage = () => {
  const isFocused = useIsFocused();
  const {isUpdating} = useCodePushUpdater();

  return <CodePushModal isOpen={isFocused && isUpdating} onClose={() => {}} />;
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
SettingsCodePushPage.propTypes = {};

SettingsCodePushPage.defaultProps = {};

export default SettingsCodePushPage;
