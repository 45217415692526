/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
`;

const PageLoadingIndicator = () => {
  return (
    <Container>
      <Indicator size={'large'} color={colors.gray.secondary} />
    </Container>
  );
};

export default PageLoadingIndicator;
