// Supermove
import {useContext, useEffect} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

// App
import CurrentSyncInventoryFormContext from 'modules/App/contexts/CurrentSyncInventoryFormContext';

const useCurrentSyncInventoryForm = ({inventoryUuid}) => {
  const {
    form,
    loading,
    setCacheInventoryUuid,
    setLoadInventoryUuid,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    hasSavedChanges,
    setHasSavedChanges,
    isSubmitting,
    setIsSubmitting,
  } = useContext(CurrentSyncInventoryFormContext);

  invariant(
    !!form,
    'useCurrentSyncInventoryForm must be used with the CurrentSyncInventoryFormContext.Provider',
  );

  useEffect(() => setCacheInventoryUuid(inventoryUuid), [setCacheInventoryUuid, inventoryUuid]);

  return {
    form,
    loading,
    setLoadInventoryUuid,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    hasSavedChanges,
    setHasSavedChanges,
    isSubmitting,
    setIsSubmitting,
  };
};

export default useCurrentSyncInventoryForm;
