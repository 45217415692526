/**
 * Component - v2.1.0
 */

// Libraries
import {createStackNavigator, createSwitchWrapperNavigator} from '@supermove/navigation';

// App
import AuthenticatedWrapper from 'modules/App/components/AuthenticatedWrapper';
import RouterWrapper from 'modules/App/components/RouterWrapper';
import Login from 'modules/Authentication/Login';
import Home from 'modules/Home';
import ListJobs from 'modules/Job/List';
import ShowJob from 'modules/Job/Show';
import InventoryShowJob from 'modules/Job/Show/Inventory';
import ShowRoom from 'modules/Room/Show';
import Settings from 'modules/Settings';
import SettingsCodePush from 'modules/Settings/CodePush';
import Start from 'modules/Start';

const Authentication = createStackNavigator(
  {
    Login: {
      path: '',
      screen: Login,
    },
  },
  {
    initialRouteName: 'Login',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const App = createStackNavigator(
  {
    Home: {
      path: '',
      screen: Home,
    },
    Settings: {
      path: 'settings',
      screen: Settings,
    },
    ListJobs: {
      path: 'jobs',
      screen: ListJobs,
    },
    ShowJob: {
      path: 'jobs/:jobUuid',
      screen: ShowJob,
    },
    InventoryShowJob: {
      path: 'jobs/:jobUuid/inventory',
      screen: InventoryShowJob,
    },
    ShowRoom: {
      path: 'jobs/:jobUuid/rooms/:roomUuid',
      screen: ShowRoom,
    },
  },
  {
    initialRouteName: 'Home',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const Authenticated = createSwitchWrapperNavigator(
  {
    App: {
      path: '',
      screen: App,
    },
  },
  {
    initialRouteName: 'App',
    backBehavior: 'history',
    wrapper: AuthenticatedWrapper,
  },
);

// A SwitchWrapperNavigator is a SwitchNavigator with an extra component rendered
// which has access to the `navigation` prop.
const Router = createSwitchWrapperNavigator(
  {
    Start: {
      path: 'start',
      screen: Start,
    },
    SettingsCodePush: {
      path: 'settings/code-push',
      screen: SettingsCodePush,
    },

    // App
    Authentication: {
      path: 'login',
      screen: Authentication,
    },
    Authenticated: {
      path: '',
      screen: Authenticated,
    },
  },
  {
    initialRouteName: 'Start',
    backBehavior: 'history',
    wrapper: RouterWrapper,
  },
);

export default Router;
